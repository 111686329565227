import './App.css';
import { useSelector } from 'react-redux';
import Login from './login';
import { Route, Routes } from 'react-router-dom';
import Splash from './splash';
import Admin from './admin';
import { Routes as RoutesComponent } from './routes';
import Api from './api';
import Application from './application';
import { CircularProgress, Modal } from '@mui/material';

function App() {
  const userSession = useSelector((state) => state.userSession);
  const loading = useSelector((state) => state.loading);
  return (
    <div className="App">
      <Modal
          open={loading}
          style={{
            textAlign: "center",
            paddingTop: "45vh"
          }}
        >
          <CircularProgress />
        </Modal>
      <Login open={!userSession} />
      <Routes>
        <Route path='/' element={<Splash />} />
        <Route path='/admin' element={<Admin />} />
        <Route path='/api/*' element={<Api />} />
        <Route path='/routes/*' element={<RoutesComponent />} />
        <Route path='/application/*' element={<Application />} />
      </Routes>
    </div>
  );
}

export default App;
