export const LOADING = 'LOADING';
export const SET_USER_SESSION = 'SET_USER_SESSION';
export const GET_APIS = 'GET_APIS';
export const SAVE_APIS = 'SAVE_APIS';
export const GET_ROUTES = 'GET_ROUTES';
export const SAVE_ROUTE = 'SAVE_ROUTE';
export const DELETE_ROUTE = 'DELETE_ROUTE';
export const REGISTER_APP = 'REGISTER_APP';

export function setUserSession(userSession) {
    console.log("Action SET_USER_SESSION");
    return { type: SET_USER_SESSION, userSession };
};

export function getApis() {
    console.log("Action GET_APIS");
    return { type: GET_APIS };
};

export function getRoutes(data) {
    console.log("Action GET_ROUTES", data);
    return { type: GET_ROUTES, data };
};

export function saveApis(data) {
    console.log("Action SAVE_APIS", data);
    return { type: SAVE_APIS, data };
};

export function saveRoute(data) {
    console.log("Action SAVE_ROUTE", data);
    return { type: SAVE_ROUTE, data}
};

export function deleteRoute(data) {
    console.log("Action DELETE_ROUTE", data);
    return { type: DELETE_ROUTE, data}
};

export function registerApp(data) {
    console.log("Action REGISTER_APP", data);
    data.type = 'register'
    return { type: REGISTER_APP, data}
}