import { Button, Checkbox, Grid, Table, TableBody, TableCell, TableRow, TextField } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteRoute, getApis, getRoutes, saveApis, saveRoute } from "../app/actions";
import { useLocation } from "react-router-dom";

export function Routes() {

    const dispatch = useDispatch()
    const apis = useSelector(state => state?.apis);
    const currentApplicationRoutes = useSelector(state => state?.routes?.applications);

    useEffect(() => { console.log('currentApplicationRoutes', currentApplicationRoutes) }, [currentApplicationRoutes])

    const location = useLocation();
    let currentAppId = location.pathname.split("/")[location.pathname.split("/").length - 1];

    const [searchAppId, setSearchAppId] = useState();
    const [newRoute, setNewRoute] = useState();

    const handleSearch = () => {
        dispatch(getRoutes({ app_id: searchAppId }))
    }

    const handleDeleteRoute = (route) => {
        console.log('delete route', route, 'from app', searchAppId)
        dispatch(deleteRoute({
            PK: searchAppId,
            SK: route,
        }))
    }

    const handleSaveRoute = (route) => {
        console.log('save route', route, 'to app', searchAppId);
        let splittedRoute = route.split('/');
        let [GSI1PK, ...rest] = splittedRoute;
        let GSI1SK = rest.join('/');
        dispatch(saveRoute({
            PK: searchAppId,
            SK: route,
            GSI1PK,
            GSI1SK
        }))
        setNewRoute('');
    }

    const [newRoutes, setNewRoutes] = useState([]);

    const handleAddNewRoute = () => {
        setNewRoutes([...newRoutes, {}]);
    };

    return (
        <React.Fragment>
            <div style={{ paddingTop: '3%' }}>
                Application management tab
            </div>

            <input
                placeholder="Search for application..."
                onChange={(e) => setSearchAppId(e.target.value)}
            />
            <Button
                style={{
                    textTransform:'none'
                }}
                onClick={handleSearch}
            >Search</Button>

            <Table style={{ width: '50%', margin: 'auto', marginTop: '20px', marginBottom: '20px', backgroundColor: 'white', padding: '20px', boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)' }}>
                <TableBody>
                    <TableRow key={'apitableheaderrow'}>
                        <TableCell align="center" style={{ fontWeight: "bolder" }}>
                            App ID
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: "bolder" }}>
                            Route
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: "bolder" }}>
                            Delete
                        </TableCell>
                    </TableRow>
                    {currentApplicationRoutes?.map((row, index) => {
                        return (
                            <TableRow key={index + 'apirow'}>
                                <TableCell align="center">
                                    {row.PK}
                                </TableCell>
                                <TableCell align="center">
                                    {row.SK}
                                </TableCell>
                                <TableCell align="center">
                                    <DeleteForeverIcon
                                        onClick={() => handleDeleteRoute(row.SK)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </TableCell>
                            </TableRow>
                        )
                    })}
                    {newRoutes.map((route, index) => (
                        <TableRow key={index + 'newrow'}>
                            <TableCell align="center">
                                {searchAppId}
                            </TableCell>
                            <TableCell align="center">
                                <input placeholder="New route..." value={newRoute} onChange={(e) => { setNewRoute(e.target.value) }} />
                            </TableCell>
                            <TableCell align="center">
                                <CheckBoxIcon onClick={() => handleSaveRoute(newRoute)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                    {currentApplicationRoutes ?
                        <TableRow>
                            <TableCell align="center" colSpan={3} onClick={handleAddNewRoute}>
                                Add new route
                            </TableCell>
                        </TableRow> : null
                    }

                </TableBody>
            </Table>
        </React.Fragment>
    )
}
