import { Button, Fade, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import sha256 from 'crypto-js/sha256';
import { useDispatch } from "react-redux";
import { registerApp } from "../app/actions";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

export default function Application(props) {

    const [password, setPassword] = useState();
    const [hashedPassword, setHashedPassword] = useState();
    const [appId, setAppId] = useState();

    const dispatch = useDispatch();

    const generatePassword = () => {
        const uuid = uuidv4().replace(/-/g, '');
        const result = uuid.substring(0, 16);

        setPassword(result);
        setHashedPassword(sha256(result).toString());
    };

    return (
        <React.Fragment>
            <div style={{ paddingTop: "20vh", textAlign: "center" }}>
                <Typography style={{ paddingBottom: '10%' }}>
                    Welcome to the Application Registration Center
                </Typography>
                <Grid
                    container
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <input placeholder="Application ID" onChange={(e) => { setAppId(e.target.value) }} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Button style={{ textTransform: 'none' }} onClick={generatePassword}>Generate Password</Button>
                        {password &&
                            <p style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                Your password: {password} <ContentPasteIcon style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(password) }} />
                            </p>
                        }
                        {password && <p>Copy and save this password, you will only see it once!</p>}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        {password && <Button style={{
                            textTransform: "none"
                        }} onClick={() => { dispatch(registerApp({ appId, hashedPassword })) }}>
                            Register application
                        </Button>}
                    </Grid>

                </Grid>
            </div>
        </React.Fragment>
    )
}