import { Button, Fade, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function Splash(props) {

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <div style={{ paddingTop: "20vh", textAlign: "center" }}>
                <Typography style={{ paddingBottom: '10%' }}>
                    Welcome to the Datalake Admin
                </Typography>
                <Grid
                    container
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Button
                            onClick={() => navigate("/api")}
                            style={{
                                textTransform: "none"
                            }}>
                            Register a new API
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Button
                            onClick={() => navigate("/application")}
                            style={{
                                textTransform: "none"
                            }}>
                            Register a new Application
                        </Button>
                    </Grid><Grid
                        item
                        xs={12}
                    >
                        <Button
                            onClick={() => navigate("/routes")}
                            style={{
                                textTransform: "none"
                            }}>
                            Manage Routes
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}