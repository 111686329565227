import {
    DELETE_ROUTE,
    GET_APIS,
    GET_ROUTES,
    REGISTER_APP,
    SAVE_APIS,
    SAVE_ROUTE,
    SET_USER_SESSION,
    LOADING
} from "../actions";

const initialState = {

};

function rootReducer(state = initialState, action) {
    if (action?.type !== "SET_USER_SESSION") {
        console.log("reducer action", action);
    };
    // state.loading = false;
    if (state.errors) {
        delete state.errors;
    };
    switch (action.type) {
        case SET_USER_SESSION:
            state = Object.assign({}, state, { userSession: action.userSession });
            return state;
        case GET_APIS:
            state = Object.assign({}, state, { apis: action.apis });
            return state;
        case GET_ROUTES:
            state = Object.assign({}, state, { routes: action.routes });
            return state;
        case DELETE_ROUTE:
            console.log("state.routes", state?.routes)
            let newRoutes = state?.routes?.applications?.filter(route => route.SK !== action.data.SK);
            state = Object.assign({}, state, { routes: { applications: newRoutes } });
            return state;
        case SAVE_ROUTE:
            const currentRoutes = [...(state?.routes?.applications || []), action.data];
            return { ...state, routes: { applications: currentRoutes } };
        case SAVE_APIS:
            return state;
        case REGISTER_APP:
            return state;
        case LOADING:
            state = Object.assign({}, state, { loading: action.loading });
            return state;
        default:
            return state;
    }
};

export default rootReducer